import React from "react";
import { NavLink } from 'react-router-dom';

import NavigationFooter from "./_NavigationFooter";

const Footer = () => {
    return(
        <footer>
            <NavigationFooter/>
        </footer>
    )
}

export default Footer