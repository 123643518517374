import React from "react";

import Picture_FluidSoftware from "../../images/easy-fluid/logiciel_fluides.jpg";

export default function EasyFluid() {
    return (
        <div className="easy-fluid">
            <div className="easy-fluid__title">
                <h2 className="titre-cartouche">NOTRE LOGICIEL Easy-Fluid</h2>
            </div>
            <div className="easy-fluid__intro">
                <p className="text-cartouche">FLYNAERO est créateur d’un logiciel, innovant permettant de simuler des écoulements autour d’obstacles dans un temps record, de rechercher l’optimum et donc d’améliorer les performances des formes étudiées et d’en accélérer leur mise sur le marché. Ainsi, notre logiciel EASY-FLUID propose une solution innovante permettant d’explorer le champ des possibles et d’analyser toutes les hypothèses afin de comprendre, prévoir et décider.</p>
            </div>
            <div className="easy-fluid__presentation">
                <img src={Picture_FluidSoftware} alt="Capture de fenêtre du logiciel Easy-Fluid" />
                <p className="text-cartouche">Couplé à des outils de CAO tel que CATIA, Easy-Fluid est l’outil idéal pour booster la créativité des ingénieurs et permettre ainsi de sortir des schémas classiques de conception.</p>
            </div>
        </div>
    )
}