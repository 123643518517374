import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MenuProvider } from './components/MenuContext.js';
import { Worker } from '@react-pdf-viewer/core';

//General

import Navigation from "./components/Navigation.js";
import Footer from "./components/Footer"
import MentionsLegales from './components/MentionsLegales.js';

//Flynaero

import Home from "./components/flynaero/Home";
import Entreprise from "./components/flynaero/Entreprise";
import Technologies from "./components/flynaero/Technologies";
import Objectifs from "./components/flynaero/Objectifs";
import Nous from "./components/flynaero/Nous";
import Partenariat from "./components/flynaero/partenariat";
import Recrutement from "./components/flynaero/Recrutement";
import Contact from "./components/flynaero/Contact";
import Actualities from './components/flynaero/Actualities';

// Bureau d'études

import BEHome from './components/bureauDetudes/Home.js';
import Realisations from './components/bureauDetudes/Realisations.js';
import Fondamentaux from './components/bureauDetudes/Fondamentaux.js';
import EasyFluidPage from './components/bureauDetudes/EasyFluid.js';

import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <div className="App">
      <MenuProvider>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
          <BrowserRouter>
            <ScrollToTop />
            < Navigation />
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/Entreprise" element={<Entreprise />} />
              <Route path="/Technologies" element={<Technologies />} />
              <Route path="/Objectifs" element={<Objectifs />} />
              <Route path="/Nous" element={<Nous />} />
              <Route path="/Partenariat" element={<Partenariat />} />
              <Route path="/Recrutement" element={<Recrutement />} />
              <Route path="/Actualites" element={<Actualities />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
              {/* Bureau d'études */}
              <Route path="/BureauEtudes" element={<BEHome/>} />
              <Route path="/BureauEtudes/Fondamentaux" element={<Fondamentaux />} />
              <Route path="/BureauEtudes/Realisations" element={<Realisations />} />
              <Route path="/BureauEtudes/Easy-Fluid" element={<EasyFluidPage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </Worker>
      </MenuProvider>
    </div>
  );
}

export default App;
