import React from "react";

import picture_ens from "../../images/partenariat/ens.jpg"
import logo_ens from "../../images/partenariat/ENS_logo_couleur_2.png"
import picture_cnrs from "../../images/partenariat/CNRS.jpg"
import logo_cnrs from "../../images/partenariat/CNRS_logo.svg"

export default function ENSCNRS() {
    return (
        <div className="enscnrs">
            <div className="enscnrs__ens">
                <div className="enscnrs__ens__images">
                    <img className="image-ens" src={picture_ens} alt="Vu face du batiment ENS" />
                    <img className="logo-ens" src={logo_ens} alt="logo ENS" />
                </div>
                <div className="enscnrs__ens__title">
                    <h2 className="titre-cartouche">Une start-up issue de l’ENS Paris-Saclay et du CNRS</h2>
                </div>
            </div>
            <div className="enscnrs__cnrs">
                <div className="enscnrs__cnrs__text">
                    <p className="text-cartouche">FLYNAERO, créée en mai 2020 avec des professionnels du monde aéronautique et de la recherche en simulation numérique est spécialisée dans la conception dans le domaine de la mécanique des fluides externes à destination de l’Aéronautique, du Naval et des simulateurs de vol.</p>
                </div>
                <div className="enscnrs__cnrs__images">
                    <img className="logo-cnrs" src={logo_cnrs} alt="Logo du CNRS" />
                    <img className="image-cnrs" src={picture_cnrs} alt="Vu aérienne du batiment CNRS Saclay" />
                </div>
            </div>
        </div>
    )
}