import { NavLink } from 'react-router-dom'

import Logo_FLYNAERO from '../images/logos/Flynaero-solo.svg';

export default function _NavigationFooter() {
  return (
    <div className='nav-footer'>
      <ul>
        <label>Flynaero</label>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Entreprise"  activeClassName="active">
            l'entreprise
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Technologies" activeClassName="active">
            la méthode DPSM
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Objectifs" activeClassName="active">
            objectifs
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Actualites" activeClassName="active">
            actualités
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Nous" activeClassName="active">
            l'équipe
          </NavLink>
        </li>
        <li><NavLink style={{ textDecoration: 'none' }} exact to="/Partenariat" activeClassName="active">
          partenariat
        </NavLink></li>
      </ul>
      <ul>
        <label>Bureau d'études</label>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes" activeClassName="active">
            bureau d'études
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Fondamentaux" activeClassName="active">
            fondamentaux
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Realisations" activeClassName="active">
            réalisations
          </NavLink>
        </li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Easy-Fluid" activeClassName="active">
            logiciel Easy Fluid
          </NavLink>
        </li>
      </ul>
      <ul>
        <label>Connexions</label>
        <li><NavLink style={{ textDecoration: 'none' }} exact to="/Recrutement" activeClassName="active">
          recrutement
        </NavLink></li>
        <li>
          <NavLink style={{ textDecoration: 'none' }} exact to="/Contact" activeClassName="active">
            contact
          </NavLink>
        </li>
        <li>
          <a style={{ textDecoration: 'none' }} target='_blank' href="https://www.linkedin.com/company/flynaero/" activeClassName="active">
            linkedIn
          </a>
        </li>
      </ul>
      <ul>
        <label></label>
        <li><img className="navBar__logo" src={Logo_FLYNAERO} alt="Logo FLYNAERO" /></li>
        <li>
          <a href="https://www.devweb.dev">©{new Date().getFullYear()} ncDevWeb</a>
        </li>
        <li>
          <NavLink exact to="/mentions-legales"> mentions légales</NavLink>
        </li>
      </ul>
    </div>
  )
};
