export default function MentionsLegales() {
    return (
        <>
            <section id="mentions_legales">
                <h1>Mentions Légales</h1>
                <p>
                    <a href="https://devweb.dev" target="_blank">ncDevWeb</a>, micro-société Spécialisé dans le secteur du développement Web, programmation informatique immatriculée SIREN 915 101 083
                </p>
                <p>
                    Développement & webmaster : <a href="https://devweb.dev" target="_blank">ncDevWeb</a>
                </p>
                <p>
                    Responsable de la publication : Flynaero
                </p>
                <p>
                    Hébergement : LWS, 10, rue Penthievre, 75008 Paris
                </p>
                <p>
                    Ce site ne contient aucun cookie, aucun trackeur et aucune donnée personnelle n'est récupérée
                </p>
            </section>
        </>
    )
};
