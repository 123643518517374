import React from "react";

import Histories from "../../bdd/histories.json"
import History from "./_EntrepriseHistory";
// Images

import Logo_FLYNAERO from "../../images/logos/Logo_FLYNAERO.jpg";

export default function Entreprise() {
    return(
            <main>
                <div className="entreprise">
                    <div className="entreprise-card">
                        <div className="entreprise-text">
                            <h2 className="titre-cartouche">L'entreprise</h2>
                            <p className="text-cartouche">FLYNAERO s'appuie sur une technologie innovante de modélisation des écoulements de fluides et de détermination de leur environnement (échange de chaleur, réactions chimiques, forces aérodynamiques, aéroacoustique).</p>
                        </div>
                    </div>
                    <div className="entreprise-picture" data-aos-anchor-placement="bottom-bottom"data-aos="flip-left" data-aos-easing="ease-in-out" data-aos-duration="1000">
                        <img src={Logo_FLYNAERO} alt="Logo FLYNAERO" />
                    </div>
                </div>
                <div className="history__card">
                    {Histories.map(history => {
                        return <History key={history.id}{...history} />
                    })}
                </div>
            </main>
    )
}