import React from "react";

// Images

import img_graph1 from "../../images/flynaero/graph1.jpg"
import img_graph2 from "../../images/flynaero/graph2.jpg"

export default function Objectifs() {
    return(
        <main name="bloc-objectifs" className="objectifs">
            <div className="objectifs-text">
                <h2 className="titre-cartouche">Les objectifs</h2>
                <p className="text-cartouche">Flynaero et son logiciel Easy Fluid est un outil de CFD, modélisation des mouvements de fluides (liquide, gaz), des propriétés de ces écoulements (vitesse, pression, température ...) et des interactions avec leur environnement (échange de chaleur, réactions chimiques, forces aérodynamiques, aéroacoustique).</p>
            </div>
            <div className="objectifs-pictures">
                <div className="objectifs-pictures-card" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="900">
                    <img src={img_graph1} alt="Graphique analyse de mécanique des fluides" />
                    <img src={img_graph2} alt="Graphique analyse de mécanique des fluides" />
                </div>
            </div>
        </main>
    )
}