import React from "react";

import Logo_FLYNAERO from "../../images/logos/Flynaero-solo.svg"

export default function HomeFondamentaux () {
    return (
        <section>
            <div className="home-fondamentaux">
                <div className="home-fondamentaux__title">
                    <h2 className="titre-cartouche">Recherche et Développement en mécanique des fluides et simulation CFD</h2>
                </div>
                <div className="home-fondamentaux__text">
                    <p className="text-cartouche">La Simulation Numérique a pris son essor dans l’industrie Aéronautique et Navale depuis plus de 30 ans, avec l’alliance de l’Analyse Numérique et de la Puissance des Nouveaux Serveurs de Calculs. La Simulation Numérique est devenue incontournable aujourd’hui pour l’industrie.</p>
                </div>
            </div>
            <div className="home-fondamentaux2">
                <div className="home-fondamentaux2__container">
                    <div className="div"></div>
                    <div className="home-fondamentaux2__title">
                        <h2 className="titre-cartouche">Une problématique impliquant des fluides ?</h2>
                        <h2 className="titre-cartouche">Nous vous proposons la solution d'accompagnement logiciel</h2>
                    </div>
                </div>
                <div className="home-fondamentaux2__content">
                    <div className="home-fondamentaux2__content__text">
                        <p className="text-cartouche">En vous donnant les clés de compréhension de phénomènes physiques complexes, nous vous aidons à améliorer vos produits et procédés. Nous vous accompagnons pour optimiser vos coûts de développement et de fabrication ou respecter les normes environnementales.</p>
                    </div>
                    <div className="home-fondamentaux2__content__card">
                        <img src={Logo_FLYNAERO} alt="Logo de FLYNAERO" />
                        <h3 className="titre-cartouche">Le conseil en ingénierie des fluides, c’est notre métier !</h3>
                    </div>
                </div>
            </div>
        </section>
    )
}