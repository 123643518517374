import React from "react";

// Images

import img_AirbusFace from "../../images/flynaero/airbus_face.png"

export default function Technologies() {
    return(
        <main name="bloc-technologies" className="technologies">
            <div className="technologies-card">
                <div className="technologies-card-text">
                    <h2 className="titre-cartouche">Technologies du futur</h2>
                    <p className="text-cartouche">La méthode appelée DPSM (Distributed Point Source Modeling), Intégrée à un logiciel de calcul de paramètres aérodynamiques basé sur la technologie des sources ponctuelles distribuée permet ainsi :</p>
                    <ul>
                        <li className="text-cartouche">- Un calcul direct tridimensionnel vrai</li>
                        <li className="text-cartouche">- Un calcul prédictif</li>
                        <li className="text-cartouche">- Une méthode non itérative</li>
                        <li className="text-cartouche">- Une identification facile des contributions de chaque élément du problème pris séparément</li>
                        <li className="text-cartouche">- Autorise des vitesses de calcul des milliers de fois plus élevées et une mise en oeuvre facilité.</li>
                    </ul>
                </div>
                <div className="technologies-card-picture">   
                    <img src={img_AirbusFace} alt="Jet de face"/>
                </div>
            </div>
        </main>
    )
}